import React from 'react';

import { Header, Footer } from "../../components";

import "./index.css";

const TrainersAgreementPolicy: React.FC = () => {
    return (
        <div className="trainers-agreement-policy-page">
            <Header />

            <div className="trainers-agreement-policy">
                <h1 id="trainers-agreement-policy">Trainers Agreement Policy</h1>

                <blockquote>
                    <p>
                        Welcome to our mobile app! By using our app, you agree to be bound by these terms and conditions (the &quot;Terms&quot;). If you do not agree to these Terms,
                        please do not use our app.
                    </p>
                </blockquote>

                <h2 id="video-content">Video Content</h2>

                <blockquote>
                    <p>Our app allows users to create and upload videos for others to view. Users have the option to make their videos either free or client-only.</p>
                </blockquote>

                <h2 id="free-videos">Free Videos</h2>

                <blockquote>
                    <p>
                        If a user chooses to make their video free, they will be entitled to 10% of the total ad revenue generated by the video. 
                        Ads may be placed before, during, or after the video is played.
                    </p>
                </blockquote>

                <h2 id="prohibited-content">Prohibited Content</h2>

                <blockquote>
                    <p>
                        Users are not allowed to upload any content that is illegal, obscene, hateful, or otherwise inappropriate. The company reserves the 
                        right to remove any content that violates these terms at any time.
                    </p>
                </blockquote>

                <h2 id="indemnification">Indemnification</h2>

                <blockquote>
                    <p>
                        You agree to indemnify and hold the company and its affiliates, officers, agents, and employees harmless from any claim or demand, 
                        including reasonable attorneys&#39; fees, made by any third party due to or arising out of your use of the app, your violation of these Terms, 
                        or your violation of any rights of another.
                    </p>
                </blockquote>

                <h2 id="termination">Termination</h2>

                <blockquote>
                    <p>The company reserves the right to terminate your access to the app at any time, for any reason, without notice.</p>
                </blockquote>

                <h2 id="changes-to-these-terms">Changes to These Terms</h2>

                <blockquote>
                    <p>
                        We reserve the right to modify these Terms at any time. If we make changes to these Terms, we will post the revised terms on this page and update the 
                        &quot;Last updated&quot; date above. Your continued use of the app after we make changes constitutes your acceptance of the revised terms. 
                        If you do not agree to the revised terms, please stop using the app.
                    </p>
                </blockquote>

                <h2 id="governing-law">Governing Law</h2>

                <blockquote>
                    <p>These Terms and your use of the app will be governed by and construed in accordance with the laws of the state of United States, without giving effect 
                        to any principles of conflicts of law. Any legal action or proceeding arising out of or relating to these Terms or the app will be brought exclusively 
                        in the federal or state courts located in the United States of America.
                    </p>
                </blockquote>

                <h2 id="entire-agreement">Entire Agreement</h2>

                <blockquote>
                    <p>
                        These Terms constitute the entire agreement between you and the company and govern your use of the app, superseding any prior agreements between 
                        you and the company.
                    </p>
                </blockquote>

                <h2 id="waiver-and-severability-of-terms">Waiver and Severability of Terms</h2>

                <blockquote>
                    <p>
                        The failure of the company to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision. 
                        If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor 
                        to give effect to the parties&#39; intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.
                    </p>
                </blockquote>

            </div>

            <Footer />
        </div>
    )
}

export default TrainersAgreementPolicy;
